@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@600&family=Roboto:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table{
text-align: left;
}
body{
	background-color: #061121;
	display: table;
	margin: auto;
	text-align: center;
	color: white; /* wallet */
	max-width: -webkit-min-content;
	max-width: min-content;
	font-weight: bold;
	font-family: Arial, san-serif;
	min-height: 100%;
}

.col{
	margin-top: 10%;
}

img{
	border-radius: 25px;
}
	
a{
	color: white;
}
	
	
.content{
	color: white; /* text */
}


.metamask-icon{
    width:10%;
}

#contact-form{
	background-image: linear-gradient(#061121, black);
	padding: 10px;
	padding-top: 25px;
	border-radius: 25px;
}

#contact-form input,
#contact-form select,
#contact-form textarea {
   width:85%;
   background: #fff;
   border: 0;
   border-radius: 4px;
   padding: 5px;
}

button {
  border-radius: 10px;
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
  background-image: linear-gradient(#def3ff8c, #000000c4);
  border: none;
  color: #000;
  transition-duration: 0.1s;
}

button:hover {
  background-color: #000;
  color: white;
}

button:active {
  background-color: #0d91e2;
}

/* Hide success/failure message
   (especially since the php is missing) */
#failure, #success {
   color: #6EA070;
}

#error {
   color: red;
}


/* mobile */
@media only screen and (max-width: 690px) {
  body {
	max-width: 600px !important;
	margin-bottom: 10%;
  }
  
  img {
	  width: 90%;
	  height: 90%;
  }
  
  .col {
	  margin: 5%;
  }



}

